
const Routes = {

    home            :   '/home',
    services        :   '/services',
    dashboard       :   '/dashboard',
    devicestatus    :   '/devicestatus',
    inspectionchecklist : '/inspections',
    inspectionform  : '/inspection-form',
    users           :   '/users',
    sensordata      :   '/sensordata',
    contactus       :   '/contactus',
    login           :   '/login',
    password        :   '/password/:token',
    reminderalert   :   '/set-offline-status-reminder/:token',
    sensorrawdata   :   '/sensorrawdata',
    e2sensordata    :   '/e2sensordata',
    settings        :   '/settings',
    groups          :   '/groups',
    pinconfig       :   '/settings/pinconfig',
    systemconfig    :   '/settings/systemconfig',
    organization    :   '/settings/organization',
    role            :   '/settings/role',
    algorithmconfig :   '/settings/algorithm-config',
    termsservice    :   '/terms'
}

export default Routes
  