export type SoapSensorData = {
    section: string,
    actual_name: string,
    original_prop: string,
    prop: string,
    status: number,
    value: number,
    alarm: boolean,
    notice: boolean,
    fail: boolean,
    overriden: boolean,
    ovTime: string | null,
    ovType: number,
    engUnits: string | null,
    dataType: string | null
}

class soapSensorStore {
    sensorData: Array<SoapSensorData>
    constructor() {
        this.sensorData =[]
    }

}

export default new soapSensorStore()